<script>
	import { _, locale } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import { flip } from 'svelte/animate';
	import { slide, fly } from 'svelte/transition';
	import { data as profileData, notification, showSpinner } from '../../stores.js';
	import HelpButton from '../button/HelpButton.svelte';
	import CloseIcon from '../icon/CloseIcon.svelte';
	import FullPageModal from '../modal/FullPageModal.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import Dropdown from '../Dropdown.svelte';
	import PhoneIcon from '../icon/PhoneIcon.svelte';
	import LoginProviderGroup from '../LoginProviderGroup.svelte';
	import MailIcon from '../icon/MailIcon.svelte';
	import Modal from '../modal/Modal.svelte';
	import RemoveButton from '../button/RemoveButton.svelte';
	import RemoveIconButton from '../button/RemoveIconButton.svelte';
	import PromoteIcon from '../icon/PromoteIcon.svelte';
	import DemoteIcon from '../icon/DemoteIcon.svelte';
	// import { WALLETCONNECT_CONFIG } from '../../constants.js';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	// import { getAddressFromAccount } from '@walletconnect/utils';
	import {
		dedupe,
		getDisplay,
		trimEthAddress,
		getWallet,
		updateAccounts,
		logPlausibleEvent
	} from '../../utils/helper';
	import * as sort from '../../utils/sort';
	import logins from '../../../../../svr/providers/logins.json';
	import EthereumProgressModal from '../modal/EthereumProgressModal.svelte';
	import { postLinkEth, postLinkEthChallenge, getProfile } from '../../utils/api-calls';
	import WarningIcon from '../icon/WarningIcon.svelte';

	export let editMode = false;
	export let verifyProvider;
	export let verifyProviderAuthority;
	export let dropdownStates = {};
	export let confirmProviderDelete;
	export let removeProviderAjax = false;
	export let removeUnverifiedProviderAjax = false;
	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};
	export let removeProvider = () => {};
	export let removeUnverifiedProvider = () => {};
	export let verifyEmailSuccess = () => {};
	export let removeRecovery = async () => {};
	export let makePreferred = async () => {};
	export let makeRecovery = async () => {};

	const flip_animation_ms = 2000;
	let emailOTPState = false;
	let phoneOTPState = false;
	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];
	// let web3ModalSign;

	async function verifyContactAuthoritySuccess() {
		const account = $profileData.profile.accounts.find((i) => i.id === verifyProviderAuthority.id);
		try {
			if (verifyProviderAuthority.action.preferred) {
				await makePreferred(account);
			} else if (verifyProviderAuthority.action.recovery) {
				await makeRecovery(account);
			} else if (verifyProviderAuthority.action.no_recovery) {
				await removeRecovery(account);
			}
		} catch (err) {
			console.error(err);
		} finally {
			verifyProviderAuthority = null;
		}
	}

	async function verifyEthereumForAuthority() {
		const account = $profileData.profile.accounts.find((i) => i.id === verifyProviderAuthority.id);
		const action = verifyProviderAuthority.action;
		verifyProviderAuthority = null; //hide verify modal to show ethereum signing state modal
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		const verify = true;
		await continueEthExtensionSigning(address, verify);
		if (action.preferred) {
			await makePreferred(account);
		} else if (action.recovery) {
			await makeRecovery(account);
		} else if (action.no_recovery) {
			await removeRecovery(account);
		}
	}

	// Note: Uncomment to show WalletConnect
	// async function verifyWalletConnectForPreferred() {
	// if(!web3ModalSign) {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch(err) {
	// 		console.error(err)
	// 		setTimeout(() => { //tbd : remove timeout - something is unsetting notification here
	// 			$notification = {
	// 			text: 'Something went wrong',
	// 				type: 'error'
	// 			}
	// 		}, 150)
	// 		return;
	// 	}
	// }

	// if (session) {
	// 	await web3ModalSign.disconnect({
	// 		topic: session.topic
	// 	});
	// }
	// session = await web3ModalSign.connect({
	// 	requiredNamespaces: {
	// 		eip155: {
	// 			methods: ['personal_sign'],
	// 			chains: ['eip155:1'],
	// 			events: []
	// 		}
	// 	}
	// });
	// const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// ethereumProgressModal = 'walletconnect';
	// ethereumProgressNotifs = [
	// 	...ethereumProgressNotifs,
	// 	{
	// 		text: $_('Wallet Connected ({address})', {
	// 			values: {
	// 				address: trimEthAddress(address)
	// 			}
	// 		}),
	// 		type: 'success',
	// 		status: $_('Waiting to sign')
	// 	}
	// ];
	// //TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// setTimeout(async () => {
	// 	continueWalletConnectSigning(address, true);
	// }, 1000);
	// }

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address, verify) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			if (verify) {
				logPlausibleEvent({
					u: `/start/verify/ethereum/extension/${getWallet().slug}`,
					n: 'action'
				});
			} else {
				logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			}
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_(`You've rejected the sign request`),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			if (verify) {
				logPlausibleEvent({ u: `/verify/ethereum/extension/${getWallet().slug}`, n: 'action' });
			} else {
				logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
				$notification = {
					text: $_('{provider} {label} has been added', {
						values: {
							provider: body.name,
							label: trimEthAddress(address)
						}
					}),
					type: 'success'
				};
			}
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
		} catch (err) {
			console.error(err);
		} finally {
			dropdownStates.provider = false;
			ethereumProgressModal = false;
			ethereumProgressNotifs = [];
		}
	}

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect() {
	// 	if (!web3ModalSign) {
	// 		try {
	// 			web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 		} catch (err) {
	// 			console.error(err);
	// 			setTimeout(() => {
	// 				//tbd : remove timeout - something is unsetting notification here
	// 				$notification = {
	// 					text: 'Something went wrong',
	// 					type: 'error'
	// 				};
	// 			}, 150);
	// 			return;
	// 		}
	// 	}

	// 	if (session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address);
	// 	}, 1000);
	// }

	// async function continueWalletConnectSigning(address, verify) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLinkEth(address);
	// 		if (verify) {
	// 			logPlausibleEvent({ u: `/start/verify/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		} else {
	// 			logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		}
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		$notification = {
	// 			text: 'Something went wrong',
	// 			type: 'error'
	// 		};
	// 		return;
	// 	}
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_('Message signed'),
	// 				type: 'success',
	// 				status: $_('Linking wallet')
	// 			}
	// 		];
	// 	} catch (err) {
	// 		console.info(err);
	// 		$notification = {
	// 			text: $_(`You've rejected the sign request`),
	// 			type: 'error'
	// 		};
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name
	// 	};

	// 	try {
	// 		await postLinkEthChallenge(body);
	// 		if (verify) {
	// 			logPlausibleEvent({
	// 				u: `/verify/ethereum/walletconnect/${slug}`,
	// 				n: 'action'
	// 			});
	// 		} else {
	// 			logPlausibleEvent({
	// 				u: `/link/ethereum/walletconnect/${slug}`,
	// 				n: 'action'
	// 			});
	// 			$notification = {
	// 				text: $_('{provider} {label} has been added', {
	// 					values: {
	// 						provider: body.name,
	// 						label: trimEthAddress(address)
	// 					}
	// 				}),
	// 				type: 'success'
	// 			};
	// 		}
	// 		$profileData = await getProfile();
	// 		$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
	// 	} catch (err) {
	// 		console.error(err);
	// 	} finally {
	// 		dropdownStates.provider = false;
	// 		ethereumProgressModal = false;
	// 		ethereumProgressNotifs = [];
	// 	}

	// 	if (verify) {
	// 		try {
	// 			const res = await putPreferred(verifyProviderAuthority.id);
	// 			$profileData.profile.accounts = res.accounts;
	// 			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
	// 			$notification = {
	// 				text: $_('{provider} {label} is now preferred', {
	// 					values: {
	// 						provider: verifyProviderAuthority.wallet?.name,
	// 						label: verifyProviderAuthority.user_name || ''
	// 					}
	// 				}),
	// 				type: 'success'
	// 			};
	// 			verifyProviderAuthority = null;
	// 		} catch (err) {
	// 			console.error(err);
	// 		}
	// 	}
	// }

	async function verifyProviderForAuthority() {
		try {
			$showSpinner = true;
			sessionStorage.setItem('authority', JSON.stringify(verifyProviderAuthority.action));
			const account = $profileData.profile.accounts.find(
				(i) => i.id === verifyProviderAuthority.id
			);
			await logPlausibleEvent({ u: `/start/verify/${account.slug}`, n: 'action' });
			window.location.href = verifyProviderAuthority.redirect_uri;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	function closeModal() {
		ethereumProgressModal = verifyProvider = verifyProviderAuthority = null;
		ethereumProgressNotifs = [];
	}

	async function verifyPhoneSuccess() {
		try {
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			if (!$profileData.merge) {
				if (verifyProvider) {
					$notification = {
						text: $_('{contact} has been verified', {
							values: {
								contact: window.intlTelInputUtils.formatNumber(
									verifyProvider,
									null,
									window.intlTelInputUtils.numberFormat.INTERNATIONAL
								)
							}
						}),
						type: 'success'
					};
				}
				verifyProvider = null;
			}
			dropdownStates.phone = false;
		} catch (err) {
			console.error(err);
		}
	}

	function verifyContactError() {
		verifyProvider = null;
	}

	$: recoveriesLength = $profileData?.profile?.accounts?.filter(
		(i) => !i.preferred && i.recovery && !i.communal
	)?.length;
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

<table id="personal-providers" class="mt-10 table-fixed w-full">
	<thead>
		<tr class="relative">
			<th class="w-1/2 md:w-3/5 text-left text-lg font-medium">
				<div class="flex items-center justify-between">
					<div class="flex items-center">
						<span>{$_('Personal Providers')}</span>
						<HelpButton
							ariaLabel="Help"
							content={$_('Providers you can use to log in or recover your Hellō Wallet')}
							placement="top"
						/>
					</div>
				</div>
			</th>
		</tr>
	</thead>
</table>

<div class="mt-2 w-full">
	{#if verifyProviderAuthority}
		<FullPageModal dataTest="verify-preferred-modal" on:close={closeModal}>
			<button aria-label="Close" on:click={closeModal} class="group absolute right-4 top-6">
				<CloseIcon />
			</button>
			{#if verifyProviderAuthority.slug === 'email'}
				<h3 class="mb-4 font-medium text-center">
					{$_('Verify your email')}
				</h3>
				<VerifyEmail
					email={verifyProviderAuthority.user_name}
					disabled
					verify
					on:success={verifyContactAuthoritySuccess}
				/>
			{:else if verifyProviderAuthority.slug === 'phone'}
				<h3 class="mb-4 font-medium text-center">
					{$_('Verify your phone')}
				</h3>
				<VerifyPhone
					phone={verifyProviderAuthority.user_name}
					disabled
					verify
					on:success={verifyContactAuthoritySuccess}
				/>
			{:else if verifyProviderAuthority.slug === 'ethereum'}
				<h3 class="mb-4 font-medium text-center">
					{$_('Verify your account')}
				</h3>
				<div class="relative h-auto btn-border rounded-md hover-none p-3 space-y-2">
					<span class="mb-3 block text-left"
						>{verifyProviderAuthority.wallet?.name || 'Ethereum'}
						{#if verifyProviderAuthority.wallet?.display}
							<span
								use:tippy={{
									content: verifyProviderAuthority.login_hint,
									placement: 'top'
								}}
							>
								({verifyProviderAuthority.wallet?.display})
							</span>
						{/if}
					</span>
					{#if verifyProviderAuthority.slug === 'ethereum' && window.ethereum}
						<LoginProvider
							autofocus={true}
							on:ethereum={verifyEthereumForAuthority}
							hideusername
							provider={verifyProviderAuthority}
							prefix="Verify with"
						/>
					{/if}

					<!-- <button
                        autofocus
                        class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
                        on:click={() => verifyWalletConnectForPreferred(verifyProviderAuthority)}
                    >
                        <div class="flex items-center gap-x-4">
                            <img
                                src="https://cdn.hello.coop/images/walletconnect.svg"
                                alt="WalletConnect"
                                class="w-4.5 max-h-[18px]"
                            />
                            <span class="block text-left">
                                {$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
                            </span>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex-shrink-0 {$locale && $locale.startsWith('ar')
                                ? 'mr-auto rotate-90'
                                : 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button> -->
				</div>
			{:else}
				<h3 class="mb-4 font-medium text-center">
					{$_('Verify your account')}
				</h3>
				<LoginProvider
					on:click={verifyProviderForAuthority}
					provider={verifyProviderAuthority}
					prefix="Verify with"
					autofocus={true}
				/>
			{/if}
		</FullPageModal>
	{/if}

	{#if verifyProvider}
		<FullPageModal dataTest="verify-modal" on:close={closeModal}>
			<button aria-label="Close" on:click={closeModal} class="group absolute right-4 top-6 z-[60]">
				<CloseIcon />
			</button>
			<div class="mb-4 opacity-80 flex items-center justify-center">
				{#if $profileData.profile.unverified_emails.includes(verifyProvider)}
					<MailIcon />
				{:else if $profileData.profile.unverified_phones.includes(verifyProvider)}
					<PhoneIcon />
				{/if}
				<h3 class="font-medium text-center ml-2">
					{$profileData.profile.unverified_emails.includes(verifyProvider)
						? $_('Verify your email')
						: $_('Verify your email')}
				</h3>
			</div>
			{#if $profileData.profile.unverified_emails.includes(verifyProvider)}
				<VerifyEmail
					email={verifyProvider}
					disabled
					verify
					on:success={verifyEmailSuccess}
					on:error={verifyContactError}
				/>
			{:else if $profileData.profile.unverified_phones.includes(verifyProvider)}
				<VerifyPhone
					phone={verifyProvider}
					disabled
					verify
					on:success={verifyPhoneSuccess}
					on:error={verifyContactError}
				/>
			{/if}
		</FullPageModal>
	{/if}

	<div class="space-y-2">
		<!-- Checking doneWizardAt here because we don't want to sort accounts in wizard flow -->
		{#if $profileData.profile.accounts.length && $profileData.actions?.doneWizardAt}
			{#each dedupe($profileData.profile.accounts, 'id')
				.filter((i) => !i.managed && !i.linkedAccount)
				.sort(sort.accounts) as account, index (account.id)}
				{@const recoveryCount = $profileData.profile.accounts.filter(
					(i) => !i.preferred && i.recovery
				).length}
				<div
					data-test="profile-provider-{index}"
					class="relative flex items-center justify-between"
					animate:flip={{ duration: flip_animation_ms }}
					transition:slide|local
				>
					<!-- (account.verifiedAt check) Delete verified vs unverified -->
					{#if confirmProviderDelete && (account.verifiedAt ? confirmProviderDelete === account.id : confirmProviderDelete === account.user_name)}
						<Modal
							position="left"
							class="md:min-w-[22rem] top-10"
							on:close={() => (confirmProviderDelete = null)}
						>
							<div class="flex flex-col md:flex-row items-center justify-between">
								<span>{$_('Confirm Deletion')}</span>
								<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
									<button
										on:click={() => (confirmProviderDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										dataTest="remove-provider-{index}-btn-confirm"
										on:click={() => {
											account.verifiedAt
												? removeProvider(account.id)
												: removeUnverifiedProvider(account.user_name);
										}}
										isLoading={account.verifiedAt
											? removeProviderAjax === account.id
											: removeUnverifiedProviderAjax === account.user_name}
										value="Delete"
									/>
								</div>
							</div>
						</Modal>
					{/if}

					<div class="flex truncate items-center w-full">
						<div
							class="w-6 flex-shrink-0 py-3 md:py-0 inline-flex items-center justify-start mt-1 md:mt-0"
						>
							{#if editMode && !account.preferred && !($profileData.profile.accounts.filter((i) => i.recovery && !i.preferred).length <= 3 && account.recovery)}
								<RemoveIconButton
									dataTest="remove-provider-{index}-btn"
									on:click={() =>
										account.verifiedAt
											? (confirmProviderDelete = account.id)
											: (confirmProviderDelete = account.user_name)}
								/>
							{/if}
						</div>
						<div
							class="relative truncate flex items-center w-full py-1 bg-charcoal bg-opacity-10 dark:bg-opacity-100 rounded-md px-4 py-3"
						>
							<span
								class="w-[54px] h-[54px] md:h-auto md:w-9 -ml-2 flex items-center justify-center flex-shrink-0"
							>
								{#if account.slug === 'ethereum' && account.wallet}
									<img
										src={account.wallet.icon}
										alt={account.wallet.name}
										class="w-4.5 max-h-[18px]"
									/>
								{:else if account.slug === 'email'}
									<MailIcon />
								{:else if account.slug === 'phone'}
									<PhoneIcon />
								{:else if account.mastodonServer}
									<img
										src="https://{account.mastodonServer}/favicon.ico"
										alt="Mastodon"
										class="w-4.5 max-h-[18px]"
									/>
								{:else}
									<img
										src="https://cdn.hello.coop/images/{account.slug}.svg"
										alt={account.slug}
										class="w-9 max-h-[36px] object-contain md:w-4.5 md:max-h-[18px] {[
											'apple',
											'twitter'
										].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
											? 'invert dark:invert-20'
											: ''}"
									/>
								{/if}
							</span>
							<div class="w-full truncate flex flex-col md:flex-row px-0.5 ml-2">
								<span
									data-test="profile-provider-{index}-label"
									class="flex-1 truncate font-medium"
									class:font-bold={account.preferred}
									class:text-red-500={!account.verifiedAt &&
										['email', 'phone'].includes(account.slug)}
								>
									{#if account.slug === 'phone'}
										{window.intlTelInputUtils.formatNumber(
											account.user_name,
											null,
											window.intlTelInputUtils.numberFormat.INTERNATIONAL
										)}
									{:else if account.slug === 'email'}
										{account.user_name}
									{:else if account.slug === 'ethereum'}
										<span
											use:tippy={{
												content: account.login_hint,
												placement: 'top'
											}}
										>
											{#if account.wallet}
												{account.wallet.name} ({account.user_name})
											{:else}
												Ethereum ({account.user_name})
											{/if}
										</span>
									{:else}
										{getDisplay(account.slug)}
										{account.mastodonServer && account.user_name
											? `(@${account.user_name}@${account.mastodonServer})`
											: account.user_name
											? `(${account.user_name})`
											: ''}
									{/if}
								</span>
								<div
									class="py-0.5 gap-x-2 flex justify-start items-center {$locale &&
									$locale.startsWith('ar')
										? 'mr-0 md:mr-3'
										: 'ml-0 md:ml-3'} md:mt-0"
								>
									{#if !account.preferred && account.recovery && account.verifiedAt && !account.linkedAccount}
										{#if recoveryCount <= 3}
											<button
												use:tippy={{
													content: $_('You cannot demote your only {count} recovery provider(s)', {
														values: { count: recoveryCount }
													}),
													placement: 'top'
												}}
												data-test="demote-{index}-btn"
												transition:fly|local={{ x: 10 }}
												class="btn-border cursor-not-allowed h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs opacity-50"
											>
												<DemoteIcon />
											</button>
										{:else}
											<button
												data-test="demote-{index}-btn"
												transition:fly|local={{ x: 10 }}
												on:click={() => removeRecovery(account)}
												class="btn-border h-4.5 w-4.5"
											>
												<DemoteIcon />
											</button>
										{/if}
									{/if}

									{#if account.preferred}
										<span
											class="inline-block text-sm font-bold uppercase truncate
                                        {$locale && $locale.startsWith('ar')
												? 'text-right md:text-left'
												: 'text-left md:text-right'} flex-shrink-0"
										>
											{$_('Preferred')}
										</span>
									{:else if account.communal}
										<span
											class="inline-block text-sm font-bold uppercase truncate
                                        {$locale && $locale.startsWith('ar')
												? 'text-right md:text-left'
												: 'text-left md:text-right'} flex-shrink-0"
										>
											{$_('Communal')}
										</span>
									{:else if account.recovery}
										<span
											transition:fly|local={{ x: 10 }}
											class="inline-block text-sm font-bold uppercase truncate
                                        {$locale && $locale.startsWith('ar')
												? 'text-right md:text-left'
												: 'text-left md:text-right'} flex-shrink-0"
										>
											{$_('Recovery')}
										</span>
									{/if}

									{#if !account.verifiedAt && ['email', 'phone'].includes(account.slug)}
										<button
											data-test="verify-{index}-btn"
											class="btn-background font-bold text-xs h-5 w-20 truncate px-1 uppercase"
											on:click={() => (verifyProvider = account.user_name)}
										>
											<span class="truncate">{$_('Verify')}</span>
										</button>
									{:else if account.preferred}
										<!-- <span class="text-xs font-semibold" /> -->
									{:else if account.communal}
										<!-- <span /> -->
										<!-- Phone can only be added as recovery and not preferred -->
									{:else if account.recovery && !['phone'].includes(account.slug)}
										<!-- <PutAuthorityButton
                                            dataTest="promote-{index}-btn"
                                            on:click={() => changePreferred(account)}
                                            value="Make Preferred"
                                            isLoading={changePreferredAjax === account.id}
                                        /> -->
									{/if}

									{#if account.linkedAccount}
										{logins.find((i) => i.slug === account.linkedAccount).display}
									{/if}

									{#if account.preferred}
										<button
											use:tippy={{
												content: $_(
													'You log in with your preferred provider. If you lose access to your preferred provider, you can log in with two recovery providers'
												),
												placement: 'top'
											}}
											transition:fly|local={{ x: 10 }}
											class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs font-medium"
										>
											?
										</button>
									{:else if account.linkedAccount}
										<img
											src="https://cdn.hello.coop/images/{account.linkedAccount}.svg"
											alt={account.linkedAccount}
											class="w-4.5 max-h-[18px] {['apple', 'twitter'].includes(
												account.linkedAccount
											) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
												? 'invert dark:invert-20'
												: ''}"
										/>
									{:else if ['instagram'].includes(account.slug)}
										<button
											use:tippy={{
												content: $_('Instagram cannot be upgraded to a recovery provider.'),
												placement: 'top'
											}}
											class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs opacity-50 cursor-not-allowed font-medium"
										>
											?
										</button>
									{:else if account.slug === 'phone' && account.recovery}
										<button
											use:tippy={{
												content: $_('Phone cannot be upgraded to a preferred provider.'),
												placement: 'top'
											}}
											class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs opacity-50 cursor-not-allowed font-medium"
										>
											?
										</button>
									{:else if account.verifiedAt}
										<button
											data-test="promote-{index}-btn"
											on:click={() =>
												account.recovery ? makePreferred(account) : makeRecovery(account)}
											class="btn-border h-4.5 w-4.5"
										>
											<PromoteIcon />
										</button>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>
			{:else}
				{#if editMode}
					<span
						class="text-fallback text-sm mt-2 {$locale && $locale.startsWith('ar')
							? 'mr-6'
							: 'ml-6'}">{$_('No providers')}</span
					>
				{/if}
			{/each}
		{/if}
	</div>
</div>

{#if !editMode}
	<section
		class="space-y-2 mt-2 {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'} p-0.5"
		transition:slide|local
	>
		{#if recoveriesLength < 2}
			<button
				on:click={() => (dropdownStates.provider = true)}
				class="btn-danger bg-red-500 text-white dark:text-charcoal w-full rounded-md py-2 px-4 flex items-center justify-start text-sm"
			>
				<div class="w-7 flex-shrink-0">
					<WarningIcon />
				</div>
				<div
					class="flex flex-col {$locale && $locale.startsWith('ar') ? 'text-right' : 'text-left'}"
				>
					<span class="font-bold uppercase">{$_('Wallet Recovery Not Set Up')}</span>
					{#if recoveriesLength === 1}
						<span class="text-xs italic">{$_('Link one more provider to complete')}</span>
					{:else}
						<span class="text-xs italic">{$_('Link two or more providers to complete')}</span>
					{/if}
				</div>
			</button>
		{/if}

		<div class="relative flex items-center">
			<Dropdown
				hasBackground={false}
				dataTest="add-provider-btn"
				ariaLabel={$_('Link another provider')}
				expanded={dropdownStates.provider}
				on:click={() => handleDropdown('provider')}
			>
				<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
					{$_('Link another provider')}
				</div>

				{#if dropdownStates.provider}
					<div class="rounded-md text-left w-full z-30 p-4 flex flex-col" transition:slide|local>
						<LoginProviderGroup
							prefix="Link"
							on:ethereum={continueWithEthereumExtension}
							on:managedEmailSuccess={verifyEmailSuccess}
							showInstagram={true}
							showManagedLogin={false}
						/>
					</div>
				{/if}
			</Dropdown>
		</div>

		<Dropdown
			hasBackground={false}
			id="email-container"
			dataTest="add-email-btn"
			ariaLabel={$profileData.profile.accounts.find((i) => i.slug === 'email')
				? $_('Verify another email')
				: $_('Verify email')}
			expanded={dropdownStates.email}
			on:click={() => handleDropdown('email')}
		>
			<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4 truncate">
				<MailIcon />
				<span class="text-left" aria-hidden="true">
					{$profileData.profile.accounts.find((i) => i.slug === 'email')
						? $_('Verify another email')
						: $_('Verify email')}
				</span>
			</div>

			{#if dropdownStates.email}
				<div class="p-4" transition:slide|local>
					<VerifyEmail
						on:otp={() => (emailOTPState = true)}
						on:success={verifyEmailSuccess}
						on:error={verifyContactError}
					/>

					{#if !emailOTPState && logins.filter((i) => i.claims.verified_email).length}
						<section class="space-y-2 mt-5" transition:slide|local>
							{#each logins.filter((i) => i.claims.verified_email) as provider}
								<LoginProvider
									on:click={(e) => continueWithProvider(provider, 'email', e.detail)}
									{provider}
									prefix="Get email from"
								/>
							{/each}
						</section>
					{/if}
				</div>
			{/if}
		</Dropdown>

		<Dropdown
			hasBackground={false}
			dataTest="add-phone-btn"
			ariaLabel={$profileData.profile.accounts.find((i) => i.slug === 'phone')
				? $_('Verify another phone')
				: $_('Verify phone')}
			expanded={dropdownStates.phone}
			on:click={() => handleDropdown('phone')}
		>
			<div class="h-12 w-full flex items-center justify-start p-4 gap-x-4">
				<PhoneIcon />
				<span class="text-left block" aria-hidden="true"
					>{$profileData.profile.accounts.find((i) => i.slug === 'phone')
						? $_('Verify another phone')
						: $_('Verify phone')}
				</span>
			</div>

			{#if dropdownStates.phone}
				<div class="p-4" transition:slide|local>
					<VerifyPhone
						on:otp={() => (phoneOTPState = true)}
						on:success={verifyPhoneSuccess}
						on:error={verifyContactError}
					/>

					{#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
						<section class="space-y-2 mt-5" transition:slide|local>
							{#each logins.filter((i) => i.claims.verified_phone) as provider}
								<div>
									<LoginProvider
										on:click={(e) => continueWithProvider(provider, 'phone', e.detail)}
										{provider}
										prefix="Get phone from"
									/>
								</div>
							{/each}
						</section>
					{/if}
				</div>
			{/if}
		</Dropdown>
	</section>
{/if}
